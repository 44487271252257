<template>
  <div class="header-container">
    <div class="header-main flex jc_b ai_c">
      <div class="f-1 flex ai_c jc_e top-header">
        <div :class="{ focus: isFocus }" class="search">
          <input @keyup.enter="onSearch" v-model="name" placeholder="请输入关键词搜索" />
          <i class="search-icon not-select" />
        </div>
        <i
          @click="goPath('/contact')"
          :class="{ active: $route.path === '/contact' }"
          class="contact-icon pointer not-select"
        >
          <!-- <span class="icon-text">联系我们</span> -->
        </i>
        <i
          @click="goPath('/help')"
          :class="{ active: $route.path === '/help' }"
          class="help-icon pointer not-select"
        >
          <!-- <span class="icon-text">帮助</span> -->
        </i>
        <i
          @click="goPath('/message')"
          :class="{
            active: $route.path === '/message',
            newMessage: newMessage.length > 0,
          }"
          class="message-icon pointer not-select"
        >
          <!-- <span class="icon-text">消息</span> -->
        </i>
        <el-popover v-model="visible" placement="bottom" width="314" trigger="click">
          <div class="popover-up jc_b ai_s">
            <div class="popover-up-user flex">
              <div class="avatar">
                <img
                  v-if="userInfo && userInfo.avatar"
                  :src="userInfo.avatar"
                  class="user-avatar"
                />
                <img
                  v-else
                  class="user-avatar"
                  src="~@/assets/image/home/home_top_img_head@3x.png"
                />
              </div>
              <div class="info">
                <p class="flex">
                  <span class="username" v-if="userInfo">{{
                    userInfo.username || userInfo.phone
                  }}</span>
                  <img
                    class="user-vip"
                    src="~@/assets/image/home/home_top_img_vip2.png"
                    v-if="userInfo.is_vip"
                  />
                  <img
                    class="user-vip"
                    src="~@/assets/image/home/home_top_img_vip.png"
                    v-else
                  />
                </p>
                <p class="txt" v-if="userInfo.is_vip">
                  {{ userInfo.jufavip_end_date.substr(0, 10) }} 到期
                </p>
                <p class="txt" v-else>暂未开通聚法会员</p>
              </div>
            </div>
            <div class="popover-up-body">
              <template v-for="v in userInfo ? userInfo.groups : []">
                <div
                  :key="v.groupId"
                  :class="{ active: v.groupId === curGroupId }"
                  class="role-item pointer not-select"
                  @click="chooseGroup(v.groupId, curGroupId)"
                >
                  <p class="groupName">
                    {{ v.groupName }}
                  </p>
                  <p class="groupBtn">{{ v.groupId === curGroupId ? "当前" : "切换" }}</p>
                </div>
              </template>
            </div>
          </div>
          <div class="popover-down flex jc_b ai_c">
            <t-btn
              @click="goPath('/personal')"
              w="88px"
              h="28px"
              fs="14px"
              type="text"
              class="bold"
              >个人资料</t-btn
            >
            <t-btn
              @click="goPath('/team')"
              w="88px"
              h="28px"
              fs="14px"
              type="text"
              class="bold"
              >小组管理</t-btn
            >
            <t-btn @click="logout" w="88px" h="28px" fs="14px" type="text" class="bold"
              >退出登录</t-btn
            >
          </div>
          <div
            :class="{ active: visible }"
            slot="reference"
            class="user-wp flex ai_c pointer not-select"
          >
            <img
              v-if="userInfo && userInfo.avatar"
              :src="userInfo.avatar"
              class="user-avatar"
            />
            <img
              v-else
              class="user-avatar"
              src="~@/assets/image/home/home_top_img_head@3x.png"
            />
            <span class="username" v-if="userInfo">{{
              userInfo.username || userInfo.phone
            }}</span>
            <img
              class="down-icon"
              src="~@/assets/image/home/btn_pull_top_triangle@2x.png"
              v-if="visible"
            />
            <img
              class="down-icon"
              src="~@/assets/image/home/btn_pull_down_triangle@2x.png"
              v-else
            />
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      // 控制搜索框长度
      isFocus: false,
      // 个人信息弹出框控制
      visible: false,
      name: "",
      newMessage: [],
    };
  },
  computed: {
    tip() {
      const { userInfo } = this;
      if (!userInfo) return "";
      let available, size;
      if (userInfo && userInfo.storage) {
        size = userInfo.storage.size;
        available = userInfo.storage.available;
      }
      return `免费享受<span style="color:#F4A93D">${
        size || "50G"
      }</span>储存空间，剩余<span style="color:#F4A93D">${available || "0G"}</span>`;
    },
    rest() {
      const { userInfo, curGroupId } = this;
      if (!userInfo) return "";
      const { groups } = userInfo;
      const cur = groups.filter((v) => v.groupId === curGroupId)[0];
      if (cur.groupType == 1) {
        return `试用版：剩余${cur.restDate}天`;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.getNewMessage();
  },
  methods: {
    ...mapMutations(["SETMSGDIALOG", "SETUSERINFO", "SETCUR"]),
    // 跳转
    goPath(href) {
      const { path } = this.$route;

      if (path === href) return;

      this.visible = false;

      this.$router.push(href);
    },
    // 退出登录
    logout() {
      const self = this;
      this.SETMSGDIALOG({
        title: "退出登录",
        message: "是否退出登录",
        width: "439px",
        type: "error",
        commit() {
          self.$api.system.logout().then(() => {
            self.SETUSERINFO(null);
            localStorage.removeItem("token");
            self.$router.push("/login");
            self.SETMSGDIALOG(null);
            self.SETCUR(null);
          });
        },
      });
    },
    // 切换小组
    chooseGroup(groupId, curGroupId) {
    
      if (groupId == this.curGroupId) return;
      const self = this;
      this.SETMSGDIALOG({
        title: "提示",
        message: "切换小组请确保阅卷系统已关闭，否则会出现数据丢失的情况",
        width: "439px",
        type: "error",
        commit() {
          self.$api.system
            .chooseGroup({
              groupId,
            })
            .then((res) => {
              self.SETCUR(groupId);
              self.SETMSGDIALOG(null);
           
              self.$api.system.getUserInfo().then(res => {
               
                self.SETUSERINFO(res)
              })
              self.$router.push({
                name: 'home'
              })
            }).catch(err =>{
                self.SETMSGDIALOG(null);
            })
        },
      });
    },
    // 点击logo
    onLogo() {
      const { path } = this.$route;
      if (path === "/") return;
      this.$router.push({
        name: "home",
      });
    },
    // 搜索
    onSearch() {
      const { name } = this;
      if (!name) return;
      this.$router.push({
        name: "search",
        params: {
          name,
        },
      });
    },
    getNewMessage() {
      this.$api.message
        .getMessageList({
          page: 1,
          perPage: 1000,
          type: 1,
        })
        .then((res) => {
          this.newMessage = res.items;
        });
    },
    refresh() {
      this.getNewMessage();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./nav.scss";
</style>
