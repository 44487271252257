<template>
    <div class="nav-container el-scrollbar">
        <el-scrollbar :native="false" class="nav-scroll">
            <div class="nav-scroll-box">
				<div class="logo">
					<img src="~@/assets/image/home/home_top_img_logo@3x.png" alt="">
				</div>                
                <div class="nav-list-box">
                    <template v-for="v in navList">
                        <div
                            @click="go(v.name)"
                            v-if="!v.permiss || curGroup && v.permiss === curGroup.groupType"
                            :key="v.name"
                            class="nav-item"
                            :class=" v.name == 'home' ? 'active' : '' ">
                            <i :class="v.icon"  class="item-icon" />
                            <span :class="{ is_active: isActive(v.name) }" class="item-text">{{ v.label }}</span>
                        </div>
                    </template>
                </div>
                <div @click="showPurchase" class="icon-vip" >
                    <img src="@/assets/image/home/nav_icon_vip@2x.png" alt="">
                </div>
            </div>
        </el-scrollbar>
        <new-case ref="newCase"></new-case>
        <purchase ref="purchase"></purchase>
        <upload-case ref="uploadCase"></upload-case>
    </div>
</template>

<script>
import Purchase from '@/components/unitDialog/purchase';
import NewCase from '@/components/unitDialog/new-case';
import UploadCase from '@/components/unitDialog/upload-case.vue';

export default {
    name : "navLayout",
    components : {
        NewCase,
        Purchase,
        UploadCase
    },
    data() {
        const div = document.getElementById("app");
        return {
            innerWidth:
                div.style.width ||
                div.clientWidth ||
                div.offsetWidth ||
                div.scrollWidth,
            navList: [
                {
                    label: "首页",
                    name: "home",
                    icon: "home_on",
                },
                {
                    label: "新建案件",
                    name: "newCase",
                    icon: "anjian_off"
                },
                {
                    label: "上传案卷",
                    name: "uploadCase",
                    icon: "shangchuan_off"
                },
                {
                    label: "阅卷系统",
                    name: "marking",
                    icon: "yuejuan_off"
                },
                {
                    label: "数据检索",
                    name: "data",
                    icon: "data_off",
                },
                {
                    label: "量刑预测",
                    name: "sentencing",
                    icon: "yuce_off",
                },
                {
                    label: "日历",
                    name: "calendar",
                    icon: "date_off"
                },
                {
                    label: "回收站",
                    name: "garbage",
                    icon: "garbage_off"
                }
            ]
          };
        },
        watch: {
            $route() {
                this.change();
            }
        },
        computed: {
            left() {
                let { innerWidth } = this;

                if (innerWidth <= 1400) return 0;

                console.info(innerWidth, 74);

                return (innerWidth - 1400) / 2;
            },
            isActive() {
                return val => {
                    const { meta, name } = this.$route;
                    return name === val || meta.isogeny === val;
                };
            }
        },
        methods: {
            change() {
                const div = document.getElementById("app");
                const width =
                    div.style.width ||
                    div.clientWidth ||
                    div.offsetWidth ||
                    div.scrollWidth;
                if (this.innerWidth === width) return;
                this.innerWidth = width;
            },
            go(name) {
              
                if( name == "newCase" ){
                    this.$refs.newCase.show();
                    return ;
                }else if( name == "uploadCase" ){
                    this.$refs.uploadCase.show();
                    return ;
                }else if (["data", "sentencing"].includes(name)) {
                    const type = name === "data" ? "data" : "lx";

                    this.$api.system
                        .jufaLogin({
                            type
                        })
                        .then(res => {
                            window.open(res.url, "_blank");
                        });
                    return;
                }
                if (name === this.$route.name) return;

                // if (name === "marking") {
                //     let link = this.$router.resolve({ name });
                //     window.open(link.href, "_blank");
                //     return;
                // }
                    
                this.$router.push({
                    name
                });
            },
            showPurchase(){
                this.$refs.purchase.show();
            }
        },
        created() {
            window.addEventListener("resize", this.change);
        },
        destroyed() {
            window.removeEventListener("resize", this.change);
        }
};
</script>

<style lang="scss" scoped>
	@import './nav.scss';
</style>